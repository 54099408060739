'use client'
import type { ThemeConfig } from 'antd';
  

const theme: ThemeConfig = {

  token: {
    colorSuccess: "#8ed46cd6",
    colorWarning: "#f9ca6d",
    colorError: "#ff9091",
    colorLink: "#5a9fff",
    colorPrimary: "#3276B1"!,
    colorInfo: "#3276b1",
   colorBgLayout: "#EBEBEB",
    //colorTextBase: "#9d9fa0",  //muda a cor da fonte padrão
    fontSize: 12,
    //  borderRadius: 16,
    // borderRadiusLG: 5,
    //  borderRadiusSM: 16,
    //  borderRadiusXS: 3,
    //  boxShadow: " 0 opx 1px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05) 0",
    //  boxShadowSecondary: " 0 px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05) 0"},
  },
  components: {
    Input: {
      hoverBorderColor: '#9d9fa0'!,
      activeBorderColor: '#9d9fa0'!,
      fontSize: 12,
      colorPrimaryHover: "#9d9fa0"!,
      colorTextDescription: "#9d9fa0"!,
      boxShadow: " 0 opx 1px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05) 0",
    ///  colorText: "#9d9fa0",
    
    },
    Typography: {
      titleMarginBottom: 0,
      paddingSM: 0,
      fontSize: 12,
      colorPrimaryHover: "#9d9fa0"!,
      colorTextDescription: "#9d9fa0"!,
      boxShadow: " 0 opx 1px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05) 0",
    ///  colorText: "#9d9fa0",
    
    },
    Form:{
      labelFontSize: 12,
      verticalLabelPadding: 0,
      itemMarginBottom: 9
    },
    Modal: {
       colorPrimary: "#3276B1",
      colorPrimaryActive: "#3276B1"
    },

   Table: {
      colorPrimary: "#3276B1"!,
      fontSize: 12,
      colorPrimaryActive: "#3276B1"!,
     colorPrimaryHover: "#3276B1"!},
    Menu: {
      fontSize: 12,
      // colorBgElevated: "rgba(255, 255, 255, 0.6)",
      // colorPrimary: "rgb(56, 123, 181)",
      //   colorPrimaryBorder: "rgb(160, 190, 208)",
      //  colorText: "rgba(123, 139, 147, 0.88)",
      //    colorTextLightSolid: "rgb(202, 196, 196)",
      // collapsedIconSize": 15,
      // activeBarWidth": 1,
      //  activeBarBorderWidth": 2,
      //   activeBarHeight: 1,
      //   collapsedWidth: 75,
      //  groupTitleFontSize: 15,
      //  iconSize: 145
      //  padding: 10,
      //  paddingXL: 31,
      //  paddingXS: 5,
      //    itemBorderRadius: 9,
      //  horizontalItemBorderRadius: 1,
      //   borderRadius: 15,
  

    },
    Button: {
      colorPrimary: "#3276B1",
      fontSize: 12,
      colorPrimaryActive: "#3276B1",
      colorPrimaryHover: "#3276B1",
    },
    Select: {
      colorPrimary: "#9d9fa0",
      colorPrimaryHover: "#9d9fa0"
    },
    Progress: {
    colorSuccess: "#3276B1"!,

    }
   
  }


};

export default theme;
